// @import-normalize; /* bring in normalize.css styles */

@import url('https://fonts.googleapis.com/css?family=Prompt:400,500,600');
* {
  font-family: Prompt;
}
html {
  height: -webkit-fill-available;
  overflow-x: hidden;
}
// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent; /* make scrollbar transparent */
//   height: 0;
// }

::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
  height: 8px;
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

body {
  margin: 0;
  padding: 0;
  background: #f2f2f2;
}
a {
  color: #ff4a4a;
}
.fit {
  max-width: 100%;
}

//#region global class for Swal image
.image-fit-cover {
  object-fit: cover;
  object-position: center;
}

.image-fit-contain {
  object-fit: contain;
  object-position: center;
}
body.modal-open {
  overflow: hidden;
}
//#endregion global class for Swal image
/* Hide scrollbar for Chrome, Safari and Opera */
// body::-webkit-scrollbar {
//   display: none;
// }

// .ReactModal__Body--open {
//   overflow: hidden;
//   position: fixed;
//   width: 100%;
//   height: 100%;
// }

.video-player > div {
  background-color: transparent !important;
}
